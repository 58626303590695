import { Button } from '@/components/ui/button.tsx';
import { Card } from '@/components/ui/card.tsx';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog.tsx';
import OrganizationForm from '@/components/forms/organization-form.tsx';
import { useState } from 'react';
import { IOrganization } from '@/types.ts';

export default function OrganizationDetailsCard({
  organization,
}: {
  organization: IOrganization;
}) {
  const [editModalOpen, setEditModalOpen] = useState(false);
  return (
    <>
      <Dialog open={editModalOpen} onOpenChange={setEditModalOpen}>
        <DialogContent className="overflow-y-scroll max-h-screen">
          <DialogTitle className="hidden">Edit Organization Form</DialogTitle>
          <DialogDescription className="hidden">
            Editing organization
          </DialogDescription>
          <OrganizationForm
            onClickCancel={() => setEditModalOpen(false)}
            organization={organization}
          />
        </DialogContent>
      </Dialog>
      <Card>
        <div className="flex justify-between">
          <h3 className="font-semibold text-lg">Org Information</h3>
          <Button
            variant="outline"
            onClick={() => setEditModalOpen(!editModalOpen)}
          >
            Edit
          </Button>
        </div>
        <dl className="detail-dl">
          <dt>Entity Name</dt>
          <dd>{organization.name}</dd>
          <dt>EIN</dt>
          <dd>{organization.business_tax_id}</dd>
          <dt>Address 1</dt>
          <dd>{organization.business_address}</dd>
          <dt>Address 2</dt>
          <dd>{organization.business_address_line_two}</dd>
          <dt>City</dt>
          <dd>{organization.business_address_city}</dd>
          <dt>State</dt>
          <dd>{organization.business_address_state}</dd>
          <dt>Zip Code</dt>
          <dd>{organization.business_address_postal_code}</dd>
        </dl>

        <h3 className="font-semibold text-lg my-4">Primary Contact</h3>
        <dl className="detail-dl">
          <dt>Primary Contact Name</dt>
          <dd>{organization.primary_contact_full_name}</dd>
          <dt>Primary Contact Phone Number</dt>
          <dd>{organization.primary_contact_phone_number}</dd>
          <dt>Primary Contact Email</dt>
          <dd>{organization.primary_contact_email}</dd>
        </dl>
      </Card>
    </>
  );
}
