import { format } from 'date-fns';
import { IThreadStatistics } from '@/types.ts';

interface IConversationHistoryProps {
  statistics?: IThreadStatistics;
}

const uniformDate = (dateString: string) => {
  return format(new Date(dateString), 'MM/dd/yyyy h:mm a');
};

export default function ConversationHistory({
  statistics,
}: IConversationHistoryProps) {
  let createdAt = 'N/A';
  let updatedAt = 'N/A';
  let length = 0;

  if (statistics) {
    const {
      conversation_created_at,
      conversation_last_updated,
      conversation_length,
    } = statistics;
    createdAt = conversation_created_at
      ? uniformDate(statistics.conversation_created_at)
      : 'N/A';
    updatedAt = conversation_last_updated
      ? uniformDate(statistics.conversation_last_updated)
      : 'N/A';
    length = conversation_length ? statistics.conversation_length : 0;
  }

  return (
    <div>
      <h3 className="font-bold text-xl mb-2">Conversation History</h3>
      <div className="flex">
        <div className="flex w-8/12 flex-col gap-y-2">
          <div className="flex gap-2">
            <p className="flex-1">Created At:</p>
            <p className="flex-1">{createdAt}</p>
          </div>
          <div className="flex gap-2">
            <p className="flex-1">Last Updated:</p>
            <p className="flex-1">{updatedAt}</p>
          </div>
          <div className="flex gap-2">
            <p className="flex-1">Conversation Length:</p>
            <p className="flex-1">{length.toString()} messages</p>
          </div>
        </div>
        <div className="flex-1"></div>
      </div>
    </div>
  );
}
