import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from '@/components/ui/form.tsx';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { ICollateralItem } from '@/types.ts';
import { Button } from '@/components/ui/button.tsx';
import { useState } from 'react';
import { sdk } from '@/api.ts';
import { FormTextField } from '@/components/text-field.tsx';

const formSchema = z.object({
  artifact_name: z.string(),
  description: z.string().optional().or(z.literal('')),
});

const EditCollateralForm = ({
  collateral,
}: {
  collateral: ICollateralItem;
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      artifact_name: collateral.artifact_name,
      description: collateral.description,
    },
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    setLoading(true);
    const { artifact_name, description } = data;
    const formData = new FormData();
    formData.append('artifact_name', artifact_name);
    formData.append('description', description as any); // TypeScript wat r u doin

    sdk
      .patchOrganizationDocument(
        collateral.organization_id,
        collateral.id,
        formData
      )
      .then(() => {
        setSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error updating document:', error);
        setError(true);
        setLoading(false);
      });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="artifact_name"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormTextField label="Name" {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormTextField label="Description" {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <div className="flex gap-x-4 items-center">
          <Button loading={loading} type="submit">
            Save
          </Button>
          <p>
            {error && 'Error updating document. Please try again.'}
            {success && 'Document updated successfully.'}
          </p>
        </div>
      </form>
    </Form>
  );
};

export default EditCollateralForm;
