import {
  Pagination as ShadCNPagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '@/components/ui/pagination.tsx';

interface ICustomPaginationProps {
  pagination: {
    page: number;
    total_pages: number;
  };
  onPageChange: (page: number) => void;
}

export const CustomPagination = ({
    pagination,
    onPageChange,
  }: ICustomPaginationProps) => {
  const { page, total_pages } = pagination;
  const isFirstPage = page === 1;
  const isLastPage = page === total_pages;
  const disabled = 'opacity-50 cursor-not-allowed';
  const visiblePagesCount = 7; // Showing 7 at a time. Kind of arbitrary, but whatevs

  const renderPageNumbers = () => {
    const pages = [];

    // Calculate the range of pages to display
    let startPage = Math.max(1, page - Math.floor(visiblePagesCount / 2));
    let endPage = Math.min(total_pages, page + Math.floor(visiblePagesCount / 2));

    // Adjust if you're near the first or last page
    if (startPage === 1) {
      endPage = Math.min(total_pages, visiblePagesCount);
    } else if (endPage === total_pages) {
      startPage = Math.max(1, total_pages - visiblePagesCount + 1);
    }

    // Show ellipsis before the first visible page if needed
    if (startPage > 1) {
      pages.push(
        <PaginationItem key={1}>
          <PaginationLink
            isActive={page === 1}
            onClick={() => onPageChange(1)}
          >
            1
          </PaginationLink>
        </PaginationItem>
      );
      if (startPage > 2) {
        pages.push(<span key="left-ellipsis">...</span>);
      }
    }

    // Show the range of pages
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <PaginationItem key={i}>
          <PaginationLink
            isActive={page === i}
            onClick={() => onPageChange(i)}
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }

    // Show ellipsis after the last visible page if needed
    if (endPage < total_pages) {
      if (endPage < total_pages - 1) {
        pages.push(<span key="right-ellipsis">...</span>);
      }
      pages.push(
        <PaginationItem key={total_pages}>
          <PaginationLink
            isActive={page === total_pages}
            onClick={() => onPageChange(total_pages)}
          >
            {total_pages}
          </PaginationLink>
        </PaginationItem>
      );
    }

    return pages;
  };

  return (
    <ShadCNPagination>
      <PaginationContent>
        <PaginationItem>
          <PaginationPrevious
            className={isFirstPage ? disabled : ''}
            onClick={() => !isFirstPage && onPageChange(page - 1)}
          />
        </PaginationItem>
        {renderPageNumbers()}
        <PaginationItem>
          <PaginationNext
            className={isLastPage ? disabled : ''}
            onClick={() => !isLastPage && onPageChange(page + 1)}
          />
        </PaginationItem>
      </PaginationContent>
    </ShadCNPagination>
  );
};
