import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import ModifyMenu from '@/routes/app/organizations/sponsors/single/modify-menu.tsx';
import { ICollateralItem } from '@/types.ts';

export const columns: ColumnDef<ICollateralItem>[] = [
  {
    accessorKey: 'artifact_name',
    header: 'File Name',
  },
  {
    accessorKey: 'created_at',
    header: 'Date Created',
    cell: ({ row }) => {
      const date: string = row.getValue('created_at');
      return format(new Date(date), 'MM/dd/yy');
    },
  },
  {
    accessorKey: 'status_active',
    header: 'Status',
    cell: ({ row }) => {
      const status: boolean = row.getValue('status_active');
      return status ? 'Active' : 'Inactive';
    },
  },
  {
    accessorKey: '',
    header: 'Modify',
    cell: ({ cell }) => {
      return <ModifyMenu data={cell.row.original} />;
    },
  },
];
