import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form.tsx';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingSpinner } from '@/components/ui/loading-spinner.tsx';
import { Button } from '@/components/ui/button.tsx';
import { useState } from 'react';
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from '@/components/ui/input-otp.tsx';
import { sdk } from '@/api.ts';
import { useAuth } from '@/providers/auth.tsx';
import { FormTextField } from '@/components/text-field.tsx';

const OTPFormSchema = z.object({
  pin: z.string().min(6, {
    message: 'Your one-time password must be 6 characters.',
  }),
});

const OTPCodeForm = ({ email }: { email: string }) => {
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { loginOTP } = useAuth();
  const form = useForm<z.infer<typeof OTPFormSchema>>({
    resolver: zodResolver(OTPFormSchema),
    defaultValues: {
      pin: '',
    },
  });

  const onSubmit = async (credentials: z.infer<typeof OTPFormSchema>) => {
    const { pin } = credentials;
    setSubmitLoading(true);
    try {
      await loginOTP(email, pin);
      setSubmitLoading(false);
    } catch (error) {
      console.error('Email login failed: ', error);
      setError(true);
      setSubmitLoading(false);
    }
  };
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="pin"
          render={({ field }) => (
            <FormItem>
              <FormLabel>One-Time Password</FormLabel>
              <FormControl>
                <div className="flex justify-center py-4">
                  <InputOTP maxLength={6} {...field}>
                    <InputOTPGroup>
                      <InputOTPSlot index={0} />
                      <InputOTPSlot index={1} />
                      <InputOTPSlot index={2} />
                      <InputOTPSlot index={3} />
                      <InputOTPSlot index={4} />
                      <InputOTPSlot index={5} />
                    </InputOTPGroup>
                  </InputOTP>
                </div>
              </FormControl>
              <FormDescription>
                Please enter the one-time password sent to your email.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex justify-between items-center mt-4">
          {error ? (
            <p className="text-destructive">
              Code is incorrect. Please try again.
            </p>
          ) : (
            <div />
          )}
          <Button type="submit">
            {!submitLoading ? (
              'Submit'
            ) : (
              <div className="px-3">
                <LoadingSpinner />
              </div>
            )}
          </Button>
        </div>
      </form>
    </Form>
  );
};

const emailFormSchema = z.object({
  email: z.string().email({ message: 'Invalid email address' }),
});

const OTPEmailForm = ({
  onSuccess,
}: {
  onSuccess: (_email: string) => void;
}) => {
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const form = useForm<z.infer<typeof emailFormSchema>>({
    resolver: zodResolver(emailFormSchema),
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (credentials: z.infer<typeof emailFormSchema>) => {
    setSubmitLoading(true);
    try {
      await sdk.createOTPCode(credentials.email);
      setSubmitLoading(false);
      onSuccess(credentials.email);
    } catch (error) {
      setSubmitLoading(false);
      setError(true);
      console.error('Email login failed: ', error);
    }
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-y-2"
      >
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormTextField
                  label="Email"
                  type="email"
                  error={form.formState.errors.email?.message}
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        {error ? (
          <p className="text-destructive my-2 text-center">
            Please check your email and try again.
          </p>
        ) : null}
        <Button type="submit" loading={submitLoading} className="w-full">
          Submit
        </Button>
      </form>
    </Form>
  );
};

const OTPLogin = () => {
  const [showCodeInput, setShowCodeInput] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>('');

  const handleEmailSuccess = (email: string) => {
    setUserEmail(email);
    setShowCodeInput(true);
  };

  return (
    <div>
      {showCodeInput ? (
        <OTPCodeForm email={userEmail} />
      ) : (
        <OTPEmailForm onSuccess={handleEmailSuccess} />
      )}
    </div>
  );
};

export default OTPLogin;
