import { create } from 'zustand/react';
import { sdk } from '@/api.ts';

interface IDocumentsStore {
  documents: [];
  pagination: {
    page: number;
    total_pages?: number;
  };
  searchQuery: string;
  handlePageChange: (page: number) => void;
  addCollateralModalOpen: boolean;
  setAddCollateralModalOpen: (isOpen: boolean) => void;
  fetchDocuments: (organizationId: string) => void;
}

const useDocumentsStore = create<IDocumentsStore>((set, get) => ({
  documents: [],
  pagination: {
    page: 1,
    total_pages: 1,
  },
  searchQuery: '',
  addCollateralModalOpen: false,
  fetchDocuments: async (organizationId: string, page?: number) => {
    try {
      const response = await sdk.getOrganizationDocuments(organizationId, {
        params: {
          page: page || get().pagination.page,
          search: get().searchQuery,
        },
      });
      const paginationObject = {
        page: response.data.page,
        per_page: response.data.per_page,
        total: response.data.total,
        total_pages: response.data.total_pages,
      };
      set({
        documents: response.data.documents,
        pagination: {
          ...paginationObject,
          page: page || get().pagination.page,
        },
      });
    } catch (error) {
      console.error('Error in fetchEmployees', error);
    }
  },
  handlePageChange: (newPage: number) =>
    set((state: Partial<IDocumentsStore>) => ({
      pagination: { ...state.pagination, page: newPage },
    })),
  setAddCollateralModalOpen: (isOpen) =>
    set({ addCollateralModalOpen: isOpen }),
}));

export default useDocumentsStore;
