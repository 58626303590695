import NewUserForm from '@/components/forms/new-user-form.tsx';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button.tsx';
import { SyntheticEvent, useEffect, useState } from 'react';
import { sdk } from '@/api.ts';
import { BiChevronRight, BiUserPlus } from 'react-icons/bi';
import { DataTable } from '@/components/data-table.tsx';
import { columns } from '@/routes/app/users/columns.tsx';
import { Card } from '@/components/ui/card.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import { useNavigate } from 'react-router-dom';
import { Input } from '@/components/ui/input.tsx';
export default function Users() {
  const navigate = useNavigate();
  const [organizations, setOrganizations] = useState([]);
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    total_pages: 1,
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [newUserModalOpen, setNewUserModalOpen] = useState(false);

  const fetchUsers = async (page: number = 1) => {
    const result = await sdk.getUsers({
      params: {
        page,
        search: searchQuery,
      },
    });
    setUsers(result.users);
    setPagination({
      page: result.page,
      total_pages: result.total_pages,
    });
  };

  useEffect(() => {
    fetchUsers(pagination.page);
  }, [pagination.page]);

  useEffect(() => {
    // Need to get ALL organizations to populate the dropdown
    sdk
      .getOrganizations({
        params: {
          all: true,
        },
      })
      .then((result) => {
        setOrganizations(result.data.organizations);
      });
  }, []);

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= pagination.total_pages) {
      setPagination((prev) => ({ ...prev, page }));
    }
  };

  return (
    <div>
      <Button
        variant="secondary"
        onClick={() => setNewUserModalOpen(!newUserModalOpen)}
      >
        Add new user <BiUserPlus size={22} className="ml-2" />
      </Button>
      <Separator className="my-4" />
      <Dialog open={newUserModalOpen} onOpenChange={setNewUserModalOpen}>
        <DialogContent className="overflow-y-scroll max-h-screen">
          <DialogHeader>
            <DialogTitle>Create a new user</DialogTitle>
            <NewUserForm organizations={organizations} />
          </DialogHeader>
        </DialogContent>
      </Dialog>
      <Card>
        <div className="flex justify-end mb-4">
          <form
            className="flex"
            onSubmit={(e) => {
              e.preventDefault();
              fetchUsers();
            }}
          >
            <Input
              placeholder="Search by email"
              onChange={(e: SyntheticEvent) =>
                setSearchQuery((e.target as HTMLInputElement).value)
              }
            />
            <Button type="submit" variant="secondary" className="ml-2">
              <BiChevronRight size={22} />
            </Button>
          </form>
        </div>
        <DataTable
          onPageChange={handlePageChange}
          data={users}
          columns={columns}
          pagination={pagination}
          navigationCallback={(data) => {
            return navigate(`/app/users/${data.id}`);
          }}
        />
      </Card>
    </div>
  );
}
