import { ColumnDef } from '@tanstack/react-table';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { format } from 'date-fns';
import { ReactNode } from 'react';

export type TUser = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  role: string;
  created_at: string;
};

const buildTooltip = (shown: ReactNode, hidden: ReactNode) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>{shown}</TooltipTrigger>
        <TooltipContent>{hidden}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export const columns: ColumnDef<TUser>[] = [
  {
    accessorKey: 'id',
    header: 'ID',
    cell: ({ row }) => {
      const id: string = row.getValue('id');
      return buildTooltip(id.slice(0, 6) + '...', id);
    },
  },
  {
    accessorKey: 'email',
    header: 'Email',
  },
  {
    accessorKey: 'first_name',
    header: 'First Name',
  },
  {
    accessorKey: 'last_name',
    header: 'Last Name',
  },
  {
    accessorKey: 'role',
    header: 'Role',
  },
  {
    accessorKey: 'created_at',
    header: 'Created At',
    cell: ({ row }) => {
      const date: string = row.getValue('created_at');
      const newDate = new Date(date);
      const formatted: string = format(newDate, 'yyyy-MM-dd');
      const full = format(newDate, 'yyyy-MM-dd HH:mm:ss');
      return buildTooltip(formatted, full);
    },
  },
];
