import {
  BiDotsHorizontalRounded,
  BiDownload,
  BiEdit,
  BiTrash,
} from 'react-icons/bi';
import { Button } from '@/components/ui/button.tsx';
import { ICollateralItem } from '@/types.ts';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover.tsx';
import { sdk } from '@/api.ts';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog.tsx';
import { useState } from 'react';
import EditCollateralForm from '@/routes/app/organizations/sponsors/single/edit-collateral-form.tsx';
import useDocumentsStore from '@/routes/app/organizations/sponsors/single/documents/store.ts';

interface ModifyMenuProps {
  data: ICollateralItem;
}

const ModifyMenu = ({ data }: ModifyMenuProps) => {
  const { id, organization_id } = data;
  const { fetchDocuments } = useDocumentsStore();
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [downloadingError, setDownloadingError] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [deletingError, setDeletingError] = useState<boolean>(false);
  const [deletingSuccess, setDeletingSuccess] = useState<boolean>(false);

  const handleClickDownload = () => {
    setDownloading(true);
    sdk
      .downloadOrganizationFile(organization_id, id)
      .then(() => {
        setDownloading(false);
      })
      .catch((error) => {
        setDownloadingError(true);
        setDownloading(false);
        console.error('Error downloading document:', error);
      });
  };

  const handleClickEdit = () => {
    setShowEditDialog(true);
  };

  const handleClickDelete = () => {
    setShowDeleteDialog(true);
  };

  const handlePerformDelete = () => {
    setDeleting(true);
    sdk
      .deleteOrganizationDocument(organization_id, id)
      .then(() => {
        setDeleting(false);
        setDeletingSuccess(true);
        fetchDocuments(organization_id);
      })
      .catch((error) => {
        setDeletingError(true);
        setDeleting(false);
        console.error('Error deleting document:', error);
      });
  };

  return (
    <div>
      {/* Edit Dialog */}
      <Dialog open={showEditDialog} onOpenChange={setShowEditDialog}>
        <DialogContent>
          <DialogTitle className="hidden">Edit Document</DialogTitle>
          <DialogDescription className="hidden">
            Editing RAG document information
          </DialogDescription>
          <EditCollateralForm collateral={data} />
        </DialogContent>
      </Dialog>

      {/* Delete Dialog */}
      <Dialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <DialogContent>
          <DialogTitle className="hidden">Delete Document</DialogTitle>
          <DialogDescription className="hidden">
            Delete RAG document
          </DialogDescription>
          <p className="my-4">Are you sure you want to delete this document?</p>
          <div className="flex gap-x-4">
            <div className="flex gap-x-2">
              <Button
                loading={deleting}
                variant="destructive"
                onClick={handlePerformDelete}
              >
                Delete
              </Button>
              <Button
                variant="outline"
                onClick={() => setShowDeleteDialog(false)}
              >
                Cancel
              </Button>
            </div>
            <p>
              {deletingError && 'Error deleting document. Please try again.'}
              {deletingSuccess && 'Document deleted successfully.'}
            </p>
          </div>
        </DialogContent>
      </Dialog>

      <Popover>
        <PopoverTrigger>
          <BiDotsHorizontalRounded size={20} />
        </PopoverTrigger>
        <PopoverContent>
          <ul>
            <li>
              <Button
                onClick={handleClickDelete}
                variant="ghost"
                className="w-full justify-start"
              >
                <BiTrash size={20} className="mr-2" />
                Delete
              </Button>
            </li>
            <li>
              <Button
                onClick={handleClickEdit}
                variant="ghost"
                className="w-full justify-start"
              >
                <BiEdit size={20} className="mr-2" />
                Edit
              </Button>
            </li>
            <li>
              <Button
                loading={downloading}
                onClick={handleClickDownload}
                variant="ghost"
                className="w-full justify-start"
              >
                <BiDownload
                  size={20}
                  color={downloadingError ? 'red' : ''}
                  className="mr-2"
                />
                <span
                  className={`${downloadingError ? 'text-destructive' : ''}`}
                >
                  Download
                </span>
              </Button>
            </li>
          </ul>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default ModifyMenu;
