import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from '@/components/ui/form.tsx';
import { Button } from '@/components/ui/button.tsx';
import { z } from 'zod';
import { useState } from 'react';
import { useAuth } from '@/providers/auth.tsx';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormTextField } from '@/components/text-field.tsx';

const formSchema = z.object({
  email: z.string().email({ message: 'Invalid email address' }),
  password: z.string().min(1, { message: 'Password is required' }),
});

const LoginPasswordForm = () => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [error, setError] = useState(false);
  const { login } = useAuth();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = async (credentials: z.infer<typeof formSchema>) => {
    setSubmitLoading(true);
    const { email, password } = credentials;
    try {
      await login(email, password);
      setSubmitLoading(false);
    } catch (error) {
      setSubmitLoading(false);
      setError(true);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormTextField
                  label="Email"
                  type="email"
                  error={form.formState.errors.email?.message}
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormTextField
                  label="Password"
                  type="password"
                  error={form.formState.errors.password?.message}
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        {error ? (
          <p className="text-destructive my-2 text-center">
            Invalid email or password. Please try again.
          </p>
        ) : null}
        <Button type="submit" loading={submitLoading} className="w-full">
          Submit
        </Button>
      </form>
    </Form>
  );
};

export default LoginPasswordForm;
