import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Card } from '@/components/ui/card.tsx';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog.tsx';
import { Button } from '@/components/ui/button.tsx';
import OrganizationDetailsCard from '@/routes/app/organizations/shared/organization-details-card.tsx';
import EditOrganizationStatusForm from '@/routes/app/organizations/sponsors/single/edit-organization-status-form.tsx';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/ui/tabs.tsx';
import useOrganizationStore from '@/routes/app/organizations/sponsors/single/store.ts';
import Invites from '@/routes/app/organizations/shared/invites';
import Employees from '@/routes/app/organizations/sponsors/single/employees';
import Documents from '@/routes/app/organizations/sponsors/single/documents';

export default function Sponsor() {
  const params = useParams();
  const { id } = params;
  const { initializeOrganizations, organization, } =
    useOrganizationStore();

  const [showStatusModal, setShowStatusModal] = useState(false);

  useEffect(() => {
    initializeOrganizations(id!);
  }, []);

  if (!organization) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Dialog open={showStatusModal} onOpenChange={setShowStatusModal}>
        <DialogContent>
          <DialogTitle>Update Status</DialogTitle>
          <DialogDescription>
            Note: Changing an organization's status can and will affect the
            sending of emails for invites. Make sure you're aware of the changes
            you're making!
          </DialogDescription>
          <EditOrganizationStatusForm
            onClickCancel={() => setShowStatusModal(false)}
            onSuccess={() => initializeOrganizations(id!)}
            organization={organization}
          />
        </DialogContent>
      </Dialog>
      <div className="flex justify-between items-center gap-x-8 mb-4">
        <h1 className="text-3xl font-semibold">Sponsor: {organization.name}</h1>
        <Button
          variant="outline"
          size="sm"
          onClick={() => setShowStatusModal(true)}
        >
          Status: {organization.organization_status}
        </Button>
      </div>
      <Tabs defaultValue="details">
        <TabsList>
          <TabsTrigger value="details">Details</TabsTrigger>
          <TabsTrigger value="documents">RAG Documents</TabsTrigger>
          <TabsTrigger value="employees">Employees</TabsTrigger>
        </TabsList>
        <TabsContent className="w-full" value="details">
          <OrganizationDetailsCard organization={organization} />
        </TabsContent>
        <TabsContent value="employees">
          <Card>
            <Employees organizationId={organization.id} />
          </Card>
        </TabsContent>
        <TabsContent value="documents">
          <Card>
            <Documents organizationId={organization.id} />
          </Card>
        </TabsContent>
        <TabsContent value="invites">
          <Invites organizationId={organization.id} />
        </TabsContent>
      </Tabs>
    </div>
  );
}
