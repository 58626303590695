import { BiChevronRight } from 'react-icons/bi';
import { Card, CardContent, CardTitle } from '@/components/ui/card.tsx';
import { Link } from 'react-router-dom';

export default function Organizations() {
  return (
    <div>
      <div className="flex gap-4">
        <Link className="flex-1" to="/app/organizations/plan_sponsor">
          <Card>
            <CardTitle className="flex justify-between items-center">
              <span>Sponsors</span>
              <BiChevronRight />
            </CardTitle>
            <CardContent className="pt-2">View &amp; add Sponsors</CardContent>
          </Card>
        </Link>
        <Link className="flex-1" to="/app/organizations/distribution_partner">
          <Card>
            <CardTitle className="flex justify-between items-center">
              <span>Advisor Firms</span>
              <BiChevronRight />
            </CardTitle>
            <CardContent className="pt-2">
              View &amp; add Advisor Firms
            </CardContent>
          </Card>
        </Link>
        <Link to="/app/organizations/marketplace_provider">
          <Card>
            <CardTitle className="flex justify-between items-center">
              <span>Marketplace</span>
              <BiChevronRight />
            </CardTitle>
            <CardContent className="pt-2">
              View &amp; add Marketplace Providers
            </CardContent>
          </Card>
        </Link>
      </div>
    </div>
  );
}
