import { TInvite } from '@/routes/app/organizations/shared/invites/columns.tsx';
import { Button } from '@/components/ui/button.tsx';
import { useState } from 'react';
import { sdk } from '@/api.ts';
import { BiEnvelope } from 'react-icons/bi';

const ReInviteButton = ({ invite }: { invite: TInvite }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    sdk.resendInvites([invite], invite.organization_id).then(() => {
      setLoading(false);
    });
  };

  return (
    <Button variant="ghost" loading={loading} onClick={handleSubmit}>
      <BiEnvelope />
    </Button>
  );
};

export default ReInviteButton;
