import { Button } from '@/components/ui/button.tsx';
import { SyntheticEvent, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog.tsx';
import { sdk } from '@/api.ts';
import { DataTable } from '@/components/data-table.tsx';
import { columns } from '@/routes/app/organizations/columns.tsx';
import { Card } from '@/components/ui/card.tsx';
import { useNavigate } from 'react-router-dom';
import OrganizationForm from '@/components/forms/organization-form.tsx';
import { TOrganizationType } from '@/types.ts';
import { Input } from '@/components/ui/input.tsx';

export default function OrganizationsTableView({
  orgType,
}: {
  orgType: TOrganizationType;
}) {
  const navigate = useNavigate();
  const [newOrgModalOpen, setNewOrgModalOpen] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    total_pages: 1,
  });
  const [searchQuery, setSearchQuery] = useState('');

  const fetchOrganizations = async (page: number = 1) => {
    sdk
      .getOrganizations({
        params: {
          page,
          search: searchQuery,
          type: orgType,
        },
      })
      .then((result) => {
        setOrganizations(result.data.organizations);
        setPagination({
          page: result.data.page,
          total_pages: result.data.total_pages,
        });
      })
      .catch(() => {
        // @TODO how to handle errors?
      });
  };

  useEffect(() => {
    fetchOrganizations(pagination.page);
  }, [pagination.page, searchQuery]);

  const handleSearchChange = (e: SyntheticEvent) => {
    const val = (e.target as HTMLInputElement).value;
    console.log('val: ', val);
    setTimeout(() => {
      setSearchQuery(val);
    }, 700);
  };

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= pagination.total_pages) {
      setPagination((prev) => ({ ...prev, page }));
    }
  };

  let pageTitle;
  switch (orgType) {
    case 'marketplace_provider':
      pageTitle = 'Marketplace Providers';
      break;
    case 'plan_sponsor':
      pageTitle = 'Sponsors';
      break;
    case 'distribution_partner':
      pageTitle = 'Advisors';
      break;
    default:
      pageTitle = 'Organizations';
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-3xl font-bold">{pageTitle}</h1>
        <Button
          variant="outline"
          onClick={() => setNewOrgModalOpen(!newOrgModalOpen)}
        >
          Add new organization
        </Button>
      </div>

      <Dialog open={newOrgModalOpen} onOpenChange={setNewOrgModalOpen}>
        <DialogContent className="max-w-screen-md">
          <DialogTitle className="hidden">New Organization Form</DialogTitle>
          <DialogDescription className="hidden">
            Create a new organization
          </DialogDescription>
          <OrganizationForm
            onClickCancel={() => setNewOrgModalOpen(false)}
            orgType={orgType}
          />
        </DialogContent>
      </Dialog>
      <Card>
        <div className="flex mb-4">
          <Input
            className="max-w-[205px]"
            placeholder="Search by name or email"
            onChange={handleSearchChange}
          />
        </div>
        <DataTable
          columns={columns}
          data={organizations}
          pagination={pagination}
          navigationCallback={(data) => {
            return navigate(`/app/organizations/${orgType}/${data.id}`);
          }}
          onPageChange={handlePageChange}
        />
      </Card>
    </div>
  );
}
