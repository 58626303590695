import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import ReInviteButton from '@/routes/app/organizations/shared/invites/reinvite-button.tsx';

export type TInvite = {
  email: string;
  first_name: string;
  last_name: string;
  role: string;
  status_accepted: boolean;
  organization_id: string;
  created_at: string;
};

export const columns: ColumnDef<TInvite>[] = [
  {
    accessorKey: 'email',
    header: 'Email',
  },
  {
    accessorKey: 'first_name',
    header: 'First Name',
  },
  {
    accessorKey: 'last_name',
    header: 'Last Name',
  },
  {
    accessorKey: 'role',
    header: 'Role',
  },
  {
    accessorKey: 'status_accepted',
    header: 'Status',
    cell: ({ row }) => {
      const status: boolean = row.getValue('status_accepted');
      return status ? 'Accepted' : 'Pending';
    },
  },
  {
    accessorKey: 'created_at',
    header: 'Date Created',
    cell: ({ row }) => {
      const date: string = row.getValue('created_at');
      return format(new Date(date), 'MM/dd/yy');
    },
  },
  {
    accessorKey: 'expires_at',
    header: 'Expires At',
    cell: ({ row }) => {
      const date: string = row.getValue('expires_at');
      return format(new Date(date), 'MM/dd/yy');
    },
  },
  {
    accessorKey: '',
    header: 'Re-invite',
    cell: ({ row }) => {
      const invite: TInvite = row.original;
      if (!invite.status_accepted) {
        return <ReInviteButton invite={invite} />;
      }
    },
  },
];
