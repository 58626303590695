import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form.tsx';
import { useEffect, useState } from 'react';
import { sdk } from '@/api.ts';
import { Button } from '@/components/ui/button.tsx';
import { FormTextField } from '@/components/text-field.tsx';

const formSchema = z.object({
  custom_file_name: z.string().min(1, 'File name is required'),
  document: z.any().refine((file) => file.length > 0, 'File is required'),
});

type FormData = z.infer<typeof formSchema>;

export default function CollateralForm({
  onSuccess,
  organizationId,
}: {
  onSuccess: () => void;
  organizationId: string;
}) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      custom_file_name: '',
      document: [],
    },
  });
  const fileRef = form.register('document');

  useEffect(() => {
    if (loading) {
      setStatusMessage('');
      return;
    }
    if (success) {
      setStatusMessage('Document uploaded successfully');
      return;
    }
    if (error) {
      setStatusMessage(
        'Error uploading file. Please check the logs or report the issue.'
      );
      return;
    }

    setStatusMessage('');
  }, [loading, success, error]);

  const onSubmit = (data: FormData) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('custom_file_name', data.custom_file_name);
    formData.append('document', data.document[0]);
    sdk
      .uploadOrganizationDocument(organizationId, formData)
      .then(() => {
        setSuccess(true);
        setError(false);
        setLoading(false);
        onSuccess();
      })
      .catch(() => {
        setError(true);
        setSuccess(false);
        setLoading(false);
      });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormField
          name="custom_file_name"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormTextField
                  label="File Name"
                  error={form.formState.errors.custom_file_name?.message}
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          name="document"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>File Upload</FormLabel>
              <FormControl>
                <FormTextField
                  type="file"
                  {...fileRef}
                  error={form.formState.errors.document?.message}
                  onChange={(event) => {
                    form.setValue(
                      'custom_file_name',
                      event.target?.files?.[0].name as string
                    );
                    field.onChange(event.target?.files?.[0] ?? undefined);
                  }}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <div className="flex gap-x-4 items-center">
          <Button type="submit" disabled={loading} loading={loading}>
            Upload File
          </Button>
          <p>{statusMessage}</p>
        </div>
      </form>
    </Form>
  );
}
