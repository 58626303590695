import { Link } from 'react-router-dom';
import { Card, CardContent, CardTitle } from '@/components/ui/card.tsx';
import { BiChevronRight } from 'react-icons/bi';

function App() {
  return (
    <div className="flex gap-4">
      <Link to="/app/organizations">
        <Card>
          <CardTitle className="flex justify-between items-center">
            <span>Organizations</span>
            <BiChevronRight />
          </CardTitle>
          <CardContent className="pt-2">
            Create and manage organizations
          </CardContent>
        </Card>
      </Link>
    </div>
  );
}

export default App;
