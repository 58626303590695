import OrganizationDetailsCard from '@/routes/app/organizations/shared/organization-details-card.tsx';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { IOrganization } from '@/types.ts';
import { sdk } from '@/api.ts';

export default function Advisor() {
  const params = useParams();
  const { id } = params;
  const [loading, setLoading] = useState(false);
  const [advisor, setAdvisor] = useState<IOrganization | null>(null);

  useEffect(() => {
    setLoading(true);
    const getAdvisorData = async () => {
      const orgResult = await sdk.getOrganization(id!);
      setAdvisor(orgResult.data);
      setLoading(false);
    };
    getAdvisorData();
  }, []);

  if (loading || !advisor) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1 className="text-3xl font-bold">Sponsor: {advisor.name}</h1>
      <div className="flex gap-x-4 mt-4">
        <OrganizationDetailsCard organization={advisor} />
      </div>
    </div>
  );
}
