import { create } from 'zustand/react';
import { sdk } from '@/api.ts';
import { devtools } from 'zustand/middleware';
import { IOrganization, IUser } from '@/types.ts';

interface IOrganizationStore {
  organization: IOrganization | null;
  organizations: IOrganization[];
  users: IUser[];
  fetchUsers: (id: string) => void;
  initializeOrganizations: (id: string) => void;
}

const useOrganizationStore = create(
  devtools<IOrganizationStore>((set) => ({
    organization: null,
    organizations: [],
    users: [],
    fetchUsers: async (id: string) => {
      try {
        const result = await sdk.getOrganizationUsers(id);
        set({ users: result.data.users });
      } catch (error) {
        console.error('Error in fetchUsers', error);
      }
    },
    initializeOrganizations: async (id: string) => {
      try {
        const allOrganizationsResponse = await sdk.getOrganizations();
        const singleOrganizationResponse = await sdk.getOrganization(id);
        const allOrganizationsUsersResponse =
          await sdk.getOrganizationUsers(id);
        set({
          organizations: allOrganizationsResponse.data.organizations,
          organization: singleOrganizationResponse.data,
          users: allOrganizationsUsersResponse.data.users,
        });
      } catch (error) {
        console.error('Error in initializeOrganizations', error);
      }
    },
  }))
);

export default useOrganizationStore;
