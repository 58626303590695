import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { sdk } from '@/api.ts';
import { Card } from '@/components/ui/card.tsx';
import UserInfo from '@/routes/app/users/single/user-info.tsx';
import { IOrganization, IThread, IUser } from '@/types.ts';
import ConversationHistory from '@/routes/app/users/single/conversation-history.tsx';
import ConversationThread from '@/routes/app/users/single/conversation-thread.tsx';
import ConversationMessages from '@/routes/app/users/single/conversation-messages.tsx';
import { CustomPagination } from '@/components/custom-pagination.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import { Button } from '@/components/ui/button.tsx';

export default function User() {
  const params = useParams();
  const { id } = params;
  const [user, setUser] = useState<IUser>({
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    email_secondary: '',
    role: '',
    created_at: '',
  });
  const [organization, setOrganization] = useState<IOrganization>({
    id: '',
    name: '',
  });
  const [thread, setThread] = useState<IThread | null>(null);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    total_pages: 1,
  });
  const [showParsedHTML, setShowParsedHTML] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getUserData = async () => {
      const userResult = await sdk.getUser(id!);
      const orgResult = await sdk.getOrganization(userResult.organization_id);
      const threadsResult = await sdk.getThread(userResult.id);
      setUser(userResult!);
      setOrganization(orgResult.data);
      setThread(threadsResult!);
      setPagination({
        page: threadsResult.page,
        total_pages: threadsResult.total_pages,
      });
      setLoading(false);
    };
    getUserData();
  }, []);

  useEffect(() => {
    if (user && user.id) {
      fetchThreadData(pagination.page);
    }
  }, [pagination.page]);

  const fetchThreadData = async (page: number) => {
    const result = await sdk.getThread(user.id, true, {
      params: {
        page,
      },
    });
    setThread(result);
    setPagination({
      page: result.page,
      total_pages: result.total_pages,
    });
  };

  const downloadChatThread = async () => {
    await sdk.downloadChatThread(user.id);
  };

  const onUpdateUser = async () => {
    const newUser = await sdk.getUser(id!);
    setUser(newUser);
    setThread(await sdk.getThread(newUser.id));
  };

  const onChangePagination = async (page: number) => {
    setPagination({
      page: page,
      total_pages: pagination.total_pages,
    });
  };

  if (loading || !user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col gap-2">
      <Card>
        <UserInfo user={user} organization={organization} />
      </Card>
      <Card>
        <ConversationHistory statistics={thread?.conversation_statistics} />
      </Card>
      <Card>
        <ConversationThread
          user={user}
          onUpdateUser={onUpdateUser}
          handleClickDownload={downloadChatThread}
        />
        {thread?.thread_history.length ? (
          <div className="flex justify-end mb-4">
            <Button
              className="rounded-full"
              onClick={() => setShowParsedHTML(!showParsedHTML)}
            >
              {showParsedHTML ? 'Show raw message' : 'Show parsed HTML'}
            </Button>
          </div>
        ) : null}
        <div className="p-4 max-h-[600px] overflow-y-scroll">
          <ConversationMessages
            showParsedHTML={showParsedHTML}
            history={thread?.thread_history ?? []}
          />
        </div>
        <Separator className="mb-4" />
        <CustomPagination
          pagination={pagination}
          onPageChange={onChangePagination}
        />
      </Card>
    </div>
  );
}
