import OrganizationDetailsCard from '@/routes/app/organizations/shared/organization-details-card.tsx';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { IOrganization } from '@/types.ts';
import { sdk } from '@/api.ts';

export default function MarketPlaceProvider() {
  const params = useParams();
  const { id } = params;
  const [loading, setLoading] = useState(false);
  const [marketplaceProvider, setMarketplaceProvider] =
    useState<IOrganization | null>(null);

  useEffect(() => {
    setLoading(true);
    const getMarketplaceProviderData = async () => {
      const orgResult = await sdk.getOrganization(id!);
      setMarketplaceProvider(orgResult.data);
      setLoading(false);
    };
    getMarketplaceProviderData();
  }, []);

  if (loading || !marketplaceProvider) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1 className="text-3xl font-bold">
        Sponsor: {marketplaceProvider.name}
      </h1>
      <div className="flex gap-x-4 mt-4">
        <OrganizationDetailsCard organization={marketplaceProvider} />
      </div>
    </div>
  );
}
