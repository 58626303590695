import { z } from 'zod';
import { IEmployee } from '@/types.ts';
import { Button } from '@/components/ui/button.tsx';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from '@/components/ui/form.tsx';
import { dateRegexV2 } from '@/lib/utils.ts';
import { sdk } from '@/api.ts';
import { FormTextField } from '@/components/text-field.tsx';

interface EditEmployeeFormProps {
  employee: IEmployee | null;
  handleCancel: () => void;
  handleSuccess: () => void;
}

const formSchema = z.object({
  hire_dt: z.union([
    z.string().regex(dateRegexV2, 'Invalid date format. Use YYYY-MM-DD'),
    z.literal(''),
  ]),
  termination_dt: z.union([
    z.string().regex(dateRegexV2, 'Invalid date format. Use YYYY-MM-DD'),
    z.literal(''),
  ]),
  rehire_dt: z.union([
    z.string().regex(dateRegexV2, 'Invalid date format. Use YYYY-MM-DD'),
    z.literal(''),
  ]),
  ytd_hours_worked: z.union([z.number(), z.string(), z.literal('')]),
});

const EditRegisteredEmployeeForm = ({
  employee,
  handleCancel,
  handleSuccess,
}: EditEmployeeFormProps) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      hire_dt: employee?.hire_dt ?? '',
      termination_dt: employee?.termination_dt ?? '',
      rehire_dt: employee?.rehire_dt ?? '',
      ytd_hours_worked: employee?.ytd_hours_worked ?? '',
    },
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    sdk
      .updateEmployee({
        ...data,
        id: employee?.id,
      })
      .then(() => {
        handleSuccess();
      })
      .catch(() => {
        // @TODO how to error here?
      });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex">
          <div className="flex-1">
            <dl className="detail-dl">
              <dt>First Name</dt>
              <dd>{employee?.first_name}</dd>
              <dt>Middle Name</dt>
              <dd>{employee?.middle_name}</dd>
              <dt>Last Name</dt>
              <dd>{employee?.last_name}</dd>
              <dt>Title</dt>
              <dd>{employee?.title}</dd>
              <dt className="mt-4">Mailing Address</dt>
              <dd>
                <address>{employee?.address_line_1}</address>
              </dd>
              <dt className="mt-4">Email Address</dt>
              <dd>{employee?.email}</dd>
              <dt className="mt-4">Home Phone</dt>
              <dd>{employee?.phone_number_home}</dd>
              <dt>Mobile Phone</dt>
              <dd>{employee?.phone_number_mobile}</dd>
            </dl>
          </div>
          <div className="flex-1 flex flex-col gap-y-2">
            <FormField
              control={form.control}
              name="hire_dt"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <FormTextField
                      label="Hire Date"
                      helperText="YYYY-MM-DD"
                      error={form.formState.errors.hire_dt?.message}
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="termination_dt"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <FormTextField
                      label="Termination Date"
                      helperText="YYYY-MM-DD"
                      error={form.formState.errors.termination_dt?.message}
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="rehire_dt"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <FormTextField
                      label="Re-hire Date"
                      helperText="YYYY-MM-DD"
                      error={form.formState.errors.rehire_dt?.message}
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="ytd_hours_worked"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <FormTextField
                      label="YTD Hours Worked"
                      type="number"
                      error={form.formState.errors.ytd_hours_worked?.message}
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        </div>
        <div className="mt-4 mb-[-1rem] flex gap-x-2">
          <Button type="submit">Save</Button>
          <Button variant="outline" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default EditRegisteredEmployeeForm;
