import { IThreadItem } from '@/types.ts';
import { format } from 'date-fns';

export default function ConversationMessages({
  history,
  showParsedHTML = false,
}: {
  showParsedHTML?: boolean;
  history: IThreadItem[];
}) {
  return (
    <div className="markdown">
      {history.map((item, index) => (
        <div key={index} className="flex flex-col gap-2 mb-4">
          <div className="flex justify-between">
            <h4 className="font-bold text-sm">
              {item.user_generated_message ? 'User' : 'Agent'}
            </h4>
            <span className="text-sm">
              {format(item.created_at, 'MMM d, y h:mm a')}
            </span>
          </div>
          {showParsedHTML ? <section className="text-sm" dangerouslySetInnerHTML={{ __html: item.message_content}} /> :
            <section className="text-sm">{item.message_content}</section>}
        </div>
      ))}
    </div>
  );
}
