import { ColumnDef } from '@tanstack/react-table';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { ReactNode } from 'react';

export type TOrganization = {
  id: string;
  name: string;
  primary_contact_full_name: string;
};

const buildTooltip = (shown: ReactNode, hidden: ReactNode) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>{shown}</TooltipTrigger>
        <TooltipContent>{hidden}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export const columns: ColumnDef<TOrganization>[] = [
  {
    accessorKey: 'id',
    header: 'ID',
    cell: ({ row }) => {
      const id: string = row.getValue('id');
      return buildTooltip(id.slice(0, 6) + '...', id);
    },
  },
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'primary_contact_full_name',
    header: 'Primary Contact',
  },
];
