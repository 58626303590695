import { Link } from 'react-router-dom';
import { IOrganization, IUser } from '@/types.ts';

export default function UserInfo({
  user,
  organization,
}: {
  user: IUser;
  organization: IOrganization;
}) {
  return (
    <>
      <h3 className="font-bold text-xl">User Information</h3>
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <p className="flex-1">First Name</p>
          <p className="flex-1">{user.first_name}</p>
        </div>
        <div className="flex gap-2">
          <p className="flex-1">Last Name</p>
          <p className="flex-1">{user.last_name}</p>
        </div>
        <div className="flex gap-2">
          <p className="flex-1">Email</p>
          <p className="flex-1">{user.email}</p>
        </div>
        <div className="flex gap-2">
          <p className="flex-1">Email Secondary</p>
          <p className="flex-1">{user.email_secondary}</p>
        </div>
        <div className="flex gap-2">
          <p className="flex-1">Role</p>
          <p className="flex-1">{user.role}</p>
        </div>
        <div className="flex gap-2">
          <p className="flex-1">Created At</p>
          <p className="flex-1">{user.created_at}</p>
        </div>
        <div className="flex gap-2">
          <p className="flex-1">Organization</p>
          <p className="flex-1">
            <Link
              className="underline text-blue-600"
              to={`/app/organizations/${organization.business_type}/${organization.id}`}
            >
              {organization.name}
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}
