import { useEffect, useState } from 'react';
import { sdk } from '@/api.ts';
import { DataTable } from '@/components/data-table.tsx';
import { columns } from '@/routes/app/organizations/shared/invites/columns.tsx';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog.tsx';
import InviteForm from '@/routes/app/organizations/shared/invites/invite-form.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Card } from '@/components/ui/card.tsx';

const Invites = ({ organizationId }: { organizationId: string }) => {
  const [invites, setInvites] = useState([]);
  const [addInviteModalOpen, setAddInviteModalOpen] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    total_pages: 1,
  });

  useEffect(() => {
    fetchInvites(pagination.page);
  }, [pagination.page]);

  const fetchInvites = async (page: number = 1) => {
    const result = await sdk.getInvites(organizationId, {
      params: {
        page,
      },
    });
    setInvites(result.data.invites);
    setPagination({
      page: result.data.page,
      total_pages: result.data.total_pages,
    });
  };

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= pagination.total_pages) {
      setPagination((prev) => ({ ...prev, page }));
    }
  };

  return (
    <Card>
      <div className="flex justify-between mb-4">
        <h3 className="font-semibold text-xl">Invites</h3>
        <Button onClick={() => setAddInviteModalOpen(!addInviteModalOpen)}>
          Invite user
        </Button>
      </div>
      <Dialog open={addInviteModalOpen} onOpenChange={setAddInviteModalOpen}>
        <DialogContent>
          <DialogTitle className="hidden">Invite new user</DialogTitle>
          <DialogDescription className="hidden">
            Invite new user
          </DialogDescription>
          <InviteForm
            onSuccess={fetchInvites}
            organizationId={organizationId}
          />
        </DialogContent>
      </Dialog>

      <DataTable
        columns={columns}
        data={invites}
        onPageChange={handlePageChange}
        pagination={pagination}
      />
    </Card>
  );
};

export default Invites;
