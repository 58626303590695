import { Input } from '@/components/ui/input.tsx';
import { forwardRef, InputHTMLAttributes } from 'react';
import { useFormField } from '@/components/ui/form.tsx';
import { Label } from '@/components/ui/label.tsx';

// @TODO add documentation to the storybook view that explains the difference
// between TextField and FormTextField, which is just that FormTextField is
// used within a react-hook-form context and TextField is used as a standalone
// input component.

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: any; // FieldError and ReactNode both throw deeper TS errors, TBD @TODO
  helperText?: string;
  label?: string;
  required?: boolean;
}

const RequiredIndicator = () => <span className="text-error">*</span>;

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ error, helperText, label, required, ...props }, ref) => {
    return (
      <div className="flex flex-col gap-y-1">
        <Label htmlFor={props.id} className="leading-5">
          {label} {required ? <RequiredIndicator /> : null}
        </Label>
        <Input
          ref={ref}
          className={error ? 'focus-visible:ring-error' : ''}
          {...props}
        />
        <span
          className={`text-sm font-medium text-${error ? 'error' : 'muted-foreground'}`}
        >
          {error ? error : helperText}
        </span>
      </div>
    );
  }
);

const FormTextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ helperText, label, required = false, ...props }, ref) => {
    const { error, formItemId } = useFormField();
    return (
      <TextField
        id={formItemId}
        label={label}
        required={required}
        helperText={helperText}
        error={error}
        ref={ref}
        {...props}
      />
    );
  }
);

export { TextField, FormTextField };
