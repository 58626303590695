import { IEmployee } from '@/types.ts';

interface EmployeeDetailProps {
  employee: IEmployee | null;
}

const AddressBlock = ({ employee }: { employee: IEmployee }) => {
  const {
    address_line_1,
    address_line_2,
    address_city,
    address_state,
    address_postal_code,
  } = employee;
  return (
    <address>
      {address_line_1} <br />
      {address_line_2}
      <br />
      {address_city} {address_city && address_state ? ',' : ''}
      {address_state} {address_postal_code}
    </address>
  );
};

const EmployeeDetail = ({ employee }: EmployeeDetailProps) => {
  if (!employee) return <div></div>;

  return (
    <div className="flex">
      <div className="flex-1">
        <dl className="detail-dl">
          <dt>First Name</dt>
          <dd>{employee?.first_name}</dd>
          <dt>Middle Name</dt>
          <dd>{employee?.middle_name}</dd>
          <dt>Last Name</dt>
          <dd>{employee?.last_name}</dd>
          <dt>Title</dt>
          <dd>{employee?.title}</dd>
          <dt className="mt-4">Mailing Address</dt>
          <dd>
            <AddressBlock employee={employee} />
          </dd>
          <dt className="mt-4">Email Address</dt>
          <dd>{employee?.email}</dd>
          <dt className="mt-4">Home Phone</dt>
          <dd>{employee?.phone_number_home}</dd>
          <dt>Mobile Phone</dt>
          <dd>{employee?.phone_number_mobile}</dd>
        </dl>
      </div>
      <div className="flex-1">
        <dl className="detail-dl">
          <dt>Hire Date</dt>
          <dd>{employee?.hire_dt ?? '-'}</dd>
          <dt>Termination Date</dt>
          <dd>{employee?.termination_dt ?? '-'}</dd>
          <dt>Rehire Date</dt>
          <dd>{employee?.rehire_dt ?? '-'}</dd>
          <dt>YTD Hours Worked</dt>
          <dd>{employee?.ytd_hours_worked ?? '-'}</dd>
          <dt>Gross Salary</dt>
          <dd>{employee?.annual_salary_gross ?? '-'}</dd>
        </dl>
      </div>
    </div>
  );
};

export default EmployeeDetail;
