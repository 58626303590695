import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form.tsx';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx';
import { Button } from '@/components/ui/button.tsx';
import { useState } from 'react';
import { IOrganization, TOrganizationStatus } from '@/types.ts';
import { sdk } from '@/api.ts';

interface EditOrganizationStatusFormProps {
  onClickCancel: () => void;
  onSuccess: () => void;
  organization: IOrganization;
}

const formSchema = z.object({
  organization_status: z.string(),
});

const EditOrganizationStatusForm = ({
  onClickCancel,
  onSuccess,
  organization,
}: EditOrganizationStatusFormProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      organization_status: organization.organization_status,
    },
  });

  const onSubmit = (data: z.infer<typeof formSchema>) => {
    setLoading(true);
    sdk
      .updateOrganizationStatus(
        organization.id,
        data as { organization_status: TOrganizationStatus }
      )
      .then(() => {
        setSuccess(true);
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
        console.log('err: ', err);
      });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          name="organization_status"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Organization Status</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger test-id="organization-status">
                    <SelectValue placeholder="Organization Status" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="onboarding">Onboarding</SelectItem>
                  <SelectItem value="active">Active</SelectItem>
                  <SelectItem value="terminated">Terminated</SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex justify-between items-center">
          <div className="flex mt-4 gap-x-4">
            <Button
              variant="outline"
              onClick={(e) => {
                e.preventDefault();
                onClickCancel();
              }}
            >
              Cancel
            </Button>
            <Button loading={loading} type="submit">
              I'm sure
            </Button>
          </div>
          <div className="pt-4">
            {success ? <span>Organization updated.</span> : null}
            {error ? <span>Error updating organization.</span> : null}
          </div>
        </div>
      </form>
    </Form>
  );
};

export default EditOrganizationStatusForm;
