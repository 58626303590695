import { useAuth } from '@/providers/auth.tsx';
import { Card, CardContent } from '@/components/ui/card.tsx';
import logo from '@/assets/images/logo/primary-orange.svg';
import { Navigate } from 'react-router-dom';
import OTPLogin from '@/routes/login/otp-login';
import { useState } from 'react';
import LoginPasswordForm from '@/routes/login/password-form.tsx';
import { Button } from '@/components/ui/button.tsx';

export default function Login() {
  const { user, loading } = useAuth();
  const [showOTPForm, setShowOTPForm] = useState(true);
  // If user /auth/me hasn't finished loading, don't yet check for user
  if (loading) {
    return <></>;
  }

  if (user) {
    return <Navigate to="/app" />;
  }

  return (
    <div className="bg-app-background h-full flex justify-center items-center">
      <div className="max-w-md mx-auto flex-1">
        <div className="px-20">
          <h2 className="text-xl text-center text-secondary font-semibold">
            Admin Panel
          </h2>
          <img src={logo} alt="Logo" />
        </div>
        <Card>
          <CardContent>
            {showOTPForm ? <OTPLogin /> : <LoginPasswordForm />}
            <Button
              test-id="otp-toggle"
              variant="link"
              className="underline w-full my-2"
              onClick={() => setShowOTPForm(!showOTPForm)}
            >
              {showOTPForm
                ? 'Log in with email + password'
                : 'Use one time password'}
            </Button>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
