import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form.tsx';
import { Input } from '@/components/ui/input.tsx';
import { Button } from '@/components/ui/button.tsx';
import { sdk } from '@/api.ts';
import { LoadingSpinner } from '@/components/ui/loading-spinner.tsx';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx';

const formSchema = z.object({
  email: z.string().email('Invalid email address').or(z.literal('')),
  first_name: z.string().min(1, 'First name is required'),
  last_name: z.string().min(1, 'Last name is required'),
  role: z.string().optional(),
});

type FormData = z.infer<typeof formSchema>;

const InviteForm = ({
  onSuccess,
  organizationId,
}: {
  onSuccess: () => void;
  organizationId: string;
}) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      first_name: '',
      last_name: '',
      role: 'user',
    },
  });

  const onSubmit = async (userData: z.infer<typeof formSchema>) => {
    setLoading(true);
    // First, check for dupes
    sdk
      .checkInvitesForDuplicates([userData])
      .then(() => {
        sdk
          .createInvites([userData], organizationId)
          .then(() => {
            setSuccess(true);
            setLoading(false);
            onSuccess();
          })
          .catch(() => {
            setError(true);
            setLoading(false);
          });
      })
      .catch(() => {
        // @TODO how to handle dupes?
        // Prob need specific messaging
        setError(true);
        setLoading(false);
      });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input type="email" placeholder="Email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="role"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="flex-1">User Role</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <div className="flex flex-1 flex-col">
                  <FormControl>
                    <SelectTrigger test-id="role-dropdown">
                      <SelectValue />
                    </SelectTrigger>
                  </FormControl>
                  <FormMessage />
                </div>
                <SelectContent>
                  <SelectItem key="user" value="user">
                    user
                  </SelectItem>
                  <SelectItem key="admin" value="admin">
                    admin
                  </SelectItem>
                </SelectContent>
              </Select>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="first_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>First Name</FormLabel>
              <FormControl>
                <Input placeholder="First Name" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="last_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Last Name</FormLabel>
              <FormControl>
                <Input placeholder="Last Name" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex justify-between items-center">
          <p>
            {error ? 'Something went wrong.' : null}
            {success ? 'Invite sent' : null}
          </p>
          <Button disabled={loading}>
            {loading ? <LoadingSpinner /> : 'Send Invite'}
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default InviteForm;
