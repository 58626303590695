import { z } from 'zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form.tsx';
import { Input } from '@/components/ui/input.tsx';
import { Button } from '@/components/ui/button.tsx';
import { sdk } from '@/api.ts';
import { TRole } from '@/types.ts';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx';

const formSchema = z.object({
  first_name: z.string(),
  last_name: z.string(),
  email: z.string().email({ message: 'Invalid email address' }),
  role: z.string().min(1, 'Role is required'),
});

export default function AddEmployeeForm({
  handleClose,
  handleSuccess,
  organizationId,
}: {
  handleClose: () => void;
  handleSuccess: () => void;
  role?: TRole;
  organizationId: string;
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      role: 'user',
    },
  });

  const onSubmit = async (formValues: z.infer<typeof formSchema>) => {
    setLoading(true);
    sdk
      .createEmployee({
        ...formValues,
        organization_id: organizationId,
      })
      .then(() => {
        setLoading(false);
        handleSuccess();
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="role"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="flex-1">User Role</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <div className="flex flex-1 flex-col">
                  <FormControl>
                    <SelectTrigger test-id="role-dropdown">
                      <SelectValue />
                    </SelectTrigger>
                  </FormControl>
                  <FormMessage />
                </div>
                <SelectContent>
                  <SelectItem key="user" value="user">
                    user
                  </SelectItem>
                  <SelectItem key="admin" value="admin">
                    admin
                  </SelectItem>
                </SelectContent>
              </Select>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="first_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>First Name</FormLabel>
              <FormControl>
                <Input test-id="employee-first-name-input" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="last_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Last Name</FormLabel>
              <FormControl>
                <Input test-id="employee-last-name-input" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input test-id="contact-email-input" type="email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex justify-between">
          <div className="flex gap-x-2 items-center">
            <Button loading={loading} type="submit">
              Create
            </Button>
            <Button onClick={handleClose} variant="outline">
              Cancel
            </Button>
          </div>
          <div className="flex items-center">
            {error && (
              <span className="text-destructive">Something went wrong.</span>
            )}
          </div>
        </div>
      </form>
    </Form>
  );
}
