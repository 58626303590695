import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx';
import { ReactNode, useEffect, useState } from 'react';
import { IOrganization } from '@/types.ts';
import { sdk } from '@/api.ts';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover.tsx';
import { Button } from '@/components/ui/button.tsx';
import { cn } from '@/lib/utils.ts';
import { Check, ChevronsUpDown } from 'lucide-react';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command.tsx';
import { Link } from 'react-router-dom';
import { BiRightArrowAlt } from 'react-icons/bi';
import { FormTextField } from '@/components/text-field.tsx';

const formSchema = z.object({
  first_name: z.string().min(2).max(50),
  last_name: z.string().min(2).max(50),
  email: z.string().email({ message: 'Invalid email address' }),
  password: z.string().optional(),
  organization_id: z.string().min(2),
  role: z.string().min(2),
});

export default function NewUserForm({
  organizations,
}: {
  organizations: IOrganization[];
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [statusMessage, setStatusMessage] = useState<ReactNode>('');

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      first_name: 'Demo',
      last_name: 'User',
      password: 'peopled',
      organization_id: organizations.find(
        (org) => org.name === 'Demo Organization'
      )?.id,
      email: `demo-${Math.floor(Math.random() * 100000)}@trypeopled.com`,
      role: 'demo_user',
    },
  });

  // Watch for changes to user type, if demo user pre-fill with demo data. If
  // user, clear form data.
  const roleField = form.watch('role');
  useEffect(() => {
    if (roleField === 'demo_user') {
      form.reset({
        role: 'demo_user',
      });
    } else {
      form.reset({
        role: roleField,
        first_name: '',
        last_name: '',
        email: '',
        password: '',
      });
    }
  }, [roleField]);

  // Using an effect here as a watcher to manage the message states
  useEffect(() => {
    if (loading) {
      setStatusMessage('');
      return;
    }
    if (success) {
      return;
    }
    if (error) {
      setStatusMessage('Error creating user. Possible duplicate');
      return;
    }

    setStatusMessage('');
  }, [loading, success, error]);

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    setLoading(true);
    sdk
      .createUser(data)
      .then((res) => {
        setSuccess(true);
        setStatusMessage(
          <Link
            className="flex underline items-center text-blue-600"
            to={`/app/users/${res.data.id}`}
          >
            View new user
            <BiRightArrowAlt className="ml-2" />
          </Link>
        );
        setError(false);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setSuccess(false);
        setLoading(false);
      });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormField
          name="role"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>User Type</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger test-id="user-role">
                    <SelectValue placeholder="User Role" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="demo_user">Demo User</SelectItem>
                  <SelectItem value="user">User (Participant)</SelectItem>
                  <SelectItem value="admin">
                    Admin (Organization admin)
                  </SelectItem>
                  <SelectItem value="advisor_officer">
                    Advisor Officer
                  </SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="organization_id"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel>Organization</FormLabel>
              <Popover modal={true}>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant="outline"
                      role="combobox"
                      test-id="organization-select"
                      className={cn(
                        'w-full justify-between',
                        !field.value && 'text-muted-foreground'
                      )}
                    >
                      {field.value
                        ? organizations.find(
                            (organization) => organization.id === field.value
                          )?.name
                        : 'Select organization'}
                      <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent className="w-full p-0">
                  <Command>
                    <CommandInput
                      test-id="organizations-search"
                      placeholder="Search organizations..."
                    />
                    <CommandList>
                      <CommandEmpty>No organizations found.</CommandEmpty>
                      <CommandGroup>
                        {organizations.map((organization) => {
                          return (
                            <CommandItem
                              value={organization.name || 'NO NAME PROVIDED'}
                              key={organization.id}
                              onSelect={() => {
                                form.setValue(
                                  'organization_id',
                                  organization.id
                                );
                              }}
                            >
                              <Check
                                className={cn(
                                  'mr-2 h-4 w-4',
                                  organization.id === field.value
                                    ? 'opacity-100'
                                    : 'opacity-0'
                                )}
                              />
                              {organization.name}
                            </CommandItem>
                          );
                        })}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="first_name"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormTextField
                  label="First Name"
                  error={form.formState.errors.first_name?.message}
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="last_name"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormTextField
                  label="Last Name"
                  error={form.formState.errors.last_name?.message}
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormTextField
                  type="email"
                  label="Email"
                  error={form.formState.errors.email?.message}
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormTextField
                  label="Password"
                  error={form.formState.errors.password?.message}
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <div className="flex items-center gap-x-4">
          <Button loading={loading}>Create User</Button>
          <div>{statusMessage}</div>
        </div>
      </form>
    </Form>
  );
}
