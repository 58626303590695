import { Button } from '@/components/ui/button.tsx';
import { BiDownload, BiTrash } from 'react-icons/bi';
import { IUser } from '@/types.ts';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from '@/components/ui/dialog.tsx';
import { useState } from 'react';
import { sdk } from '@/api.ts';

interface ConversationThreadProps {
  handleClickDownload: () => void;
  onUpdateUser: () => void;
  user: IUser | null;
}

export default function ConversationThread({
  handleClickDownload,
  onUpdateUser,
  user,
}: ConversationThreadProps) {
  const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const threadId = user?.openai_thread_id;
  const handleClickDelete = () => {
    setShowDeleteWarningModal(true);
  };

  const handlePerformDelete = async () => {
    try {
      setShowDeleteWarningModal(false);
      setLoading(true);
      await sdk.patchDeleteThread(user!.id);
      onUpdateUser();
      setLoading(false);
    } catch (error) {
      console.error('Error deleting conversation thread:', error);
      setLoading(false);
    }
  };

  return (
    <div>
      <Dialog
        open={showDeleteWarningModal}
        onOpenChange={setShowDeleteWarningModal}
      >
        <DialogContent>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogDescription>
            Deleting a conversation is not reversible.
          </DialogDescription>
          <DialogFooter className="gap-y-2">
            <Button
              onClick={() => setShowDeleteWarningModal(false)}
              className="rounded px-3"
              variant="outline"
            >
              Cancel
            </Button>
            <Button
              onClick={handlePerformDelete}
              className="rounded px-3 bg-destructive"
            >
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <div className="flex justify-between">
        <div className="flex flex-col pb-2">
          <h3 className="font-bold text-xl">Conversation Thread</h3>
          <p className="text-xs">
            Thread ID: {threadId ?? 'No thread for this user.'}
          </p>
        </div>
        <div className="flex gap-2">
          <Button
            onClick={handleClickDownload}
            className="rounded-full px-3 bg-dark"
            disabled={!threadId || loading}
          >
            <BiDownload />
          </Button>
          <Button
            onClick={handleClickDelete}
            className="rounded-full px-3 bg-destructive"
            disabled={!threadId || loading}
          >
            <BiTrash />
          </Button>
        </div>
      </div>
    </div>
  );
}
