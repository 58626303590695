import { useAuth } from '@/providers/auth.tsx';
import { Navigate } from 'react-router-dom';
import { ReactNode } from 'react';

export default function ProtectedRoute({ element }: { element: ReactNode }) {
  const { user, loading } = useAuth();

  if (loading) {
    return 'Loading...';
  }
  return user ? element : <Navigate to="/login" />;
}
