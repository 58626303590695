import { z } from 'zod';
import { IEmployee } from '@/types.ts';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form.tsx';
import { Input } from '@/components/ui/input.tsx';
import { Button } from '@/components/ui/button.tsx';
import { sdk } from '@/api.ts';
import { dateRegexV2, statesList } from '@/lib/utils.ts';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx';

interface EditEmployeeFormProps {
  employee: IEmployee | null;
  handleCancel: () => void;
  handleClose: () => void;
  handleSuccess: () => void;
}

const formSchema = z.object({
  first_name: z.union([z.string(), z.literal('')]),
  middle_name: z.union([z.string(), z.literal('')]),
  last_name: z.union([z.string(), z.literal('')]),
  title: z.union([z.string(), z.literal('')]),
  address_line_1: z.union([z.string(), z.literal('')]),
  address_line_2: z.union([z.string(), z.literal('')]),
  address_city: z.union([z.string(), z.literal('')]),
  address_state: z.union([z.string(), z.literal('')]),
  address_postal_code: z.union([z.string(), z.literal('')]),
  email: z.string().email({ message: 'Invalid email address' }),
  phone_number_home: z.union([z.string(), z.literal('')]),
  phone_number_mobile: z.union([z.string(), z.literal('')]),
  hire_dt: z.union([
    z.string().regex(dateRegexV2, 'Invalid date format. Use YYYY-MM-DD'),
    z.literal(''),
  ]),
  termination_dt: z.union([
    z.string().regex(dateRegexV2, 'Invalid date format. Use YYYY-MM-DD'),
    z.literal(''),
  ]),
  rehire_dt: z.union([
    z.string().regex(dateRegexV2, 'Invalid date format. Use YYYY-MM-DD'),
    z.literal(''),
  ]),
  ytd_hours_worked: z.union([z.number(), z.string(), z.literal('')]),
});

const EditInvitedEmployeeForm = ({
  employee,
  handleCancel,
  handleSuccess,
}: EditEmployeeFormProps) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      first_name: employee?.first_name ?? '',
      middle_name: employee?.middle_name ?? '',
      last_name: employee?.last_name ?? '',
      title: employee?.title ?? '',
      address_line_1: employee?.address_line_1 ?? '',
      address_line_2: employee?.address_line_2 ?? '',
      address_city: employee?.address_city ?? '',
      address_state: employee?.address_state ?? '',
      address_postal_code: employee?.address_postal_code ?? '',
      email: employee?.email,
      phone_number_home: employee?.phone_number_home ?? '',
      phone_number_mobile: employee?.phone_number_mobile ?? '',
      hire_dt: employee?.hire_dt ?? '',
      termination_dt: employee?.termination_dt ?? '',
      rehire_dt: employee?.rehire_dt ?? '',
      ytd_hours_worked: employee?.ytd_hours_worked ?? '',
    },
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    sdk
      .updateEmployee({
        ...data,
        id: employee?.id,
      })
      .then(() => {
        handleSuccess();
      })
      .catch(() => {
        // @TODO how to error?
      });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex gap-x-4">
          <div className="flex flex-col gap-y-4">
            <FormField
              control={form.control}
              name="first_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>First Name</FormLabel>
                  <FormControl>
                    <Input test-id="first-name-input" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="middle_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Middle Name</FormLabel>
                  <FormControl>
                    <Input test-id="middle-name-input" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="last_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Last Name</FormLabel>
                  <FormControl>
                    <Input test-id="last-name-input" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Job Title</FormLabel>
                  <FormControl>
                    <Input test-id="title-input" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="flex flex-col gap-y-4">
            <FormField
              control={form.control}
              name="address_line_1"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Address Line 1</FormLabel>
                  <FormControl>
                    <Input test-id="address-line-1-input" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="address_line_2"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Address Line 2</FormLabel>
                  <FormControl>
                    <Input test-id="address-line-2-input" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex gap-x-2">
              <FormField
                control={form.control}
                name="address_city"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>City</FormLabel>
                    <FormControl>
                      <Input test-id="address-city-input" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="address_state"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>State</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <div className="flex flex-1 flex-col">
                        <FormControl>
                          <SelectTrigger test-id="edit-employee-state-dropdown">
                            <SelectValue placeholder="State" />
                          </SelectTrigger>
                        </FormControl>
                        <FormMessage />
                      </div>
                      <SelectContent>
                        {statesList.map((state) => (
                          <SelectItem
                            key={state.abbreviation}
                            value={state.abbreviation}
                          >
                            {state.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="address_postal_code"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Postal Code</FormLabel>
                    <FormControl>
                      <Input test-id="address-postal-code-input" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input type="email" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex gap-x-2">
              <FormField
                control={form.control}
                name="phone_number_home"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Home Phone</FormLabel>
                    <FormControl>
                      <Input test-id="phone-number-home-input" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="phone_number_mobile"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Mobile Phone</FormLabel>
                    <FormControl>
                      <Input test-id="phone-number-mobile-input" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="flex flex-col gap-y-4">
            <FormField
              control={form.control}
              name="hire_dt"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Hire Date</FormLabel>
                  <FormControl>
                    <Input test-id="hire-date-input" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="termination_dt"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Termination Date</FormLabel>
                  <FormControl>
                    <Input test-id="term-date-input" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="rehire_dt"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Re-hire Date</FormLabel>
                  <FormControl>
                    <Input test-id="rehire-date-input" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="ytd_hours_worked"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>YTD Hours Worked</FormLabel>
                  <FormControl>
                    <Input test-id="ytd-hours-worked-input" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        <div className="mt-4 mb-[-1rem] flex gap-x-2">
          <Button type="submit">Save</Button>
          <Button variant="outline" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default EditInvitedEmployeeForm;
