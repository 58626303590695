import { Button } from '@/components/ui/button.tsx';
import useDocumentsStore from '@/routes/app/organizations/sponsors/single/documents/store.ts';
import { useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog.tsx';
import CollateralForm from '@/components/forms/collateral-form.tsx';
import { DataTable } from '@/components/data-table.tsx';
import { columns } from '@/routes/app/organizations/sponsors/single/documents/columns.tsx';

interface DocumentsProps {
  organizationId: string;
}

const Documents = ({ organizationId }: DocumentsProps) => {
  const {
    addCollateralModalOpen,
    setAddCollateralModalOpen,
    documents,
    fetchDocuments,
    pagination,
    handlePageChange,
  } = useDocumentsStore();

  useEffect(() => {
    fetchDocuments(organizationId);
  }, [pagination.page, organizationId]);

  return (
    <div>
      <div className="flex justify-between mb-4 items-center">
        <h3 className="font-semibold text-xl">Sponsor RAGS</h3>
        <Button
          onClick={() => setAddCollateralModalOpen(!addCollateralModalOpen)}
        >
          Add Document
        </Button>
      </div>
      <Dialog
        open={addCollateralModalOpen}
        onOpenChange={setAddCollateralModalOpen}
      >
        <DialogContent className="overflow-y-scroll max-h-screen">
          <DialogTitle className="hidden">Add Document</DialogTitle>
          <DialogDescription className="hidden">
            Adding document to RAG
          </DialogDescription>
          <CollateralForm
            onSuccess={() => fetchDocuments(organizationId)}
            organizationId={organizationId}
          />
        </DialogContent>
      </Dialog>
      <DataTable
        columns={columns}
        data={documents}
        pagination={pagination}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default Documents;
