import { ColumnDef } from '@tanstack/react-table';

interface IEmployee {
  first_name: string;
  last_name: string;
  email: string;
}

export const columns: ColumnDef<IEmployee>[] = [
  {
    accessorKey: 'first_name',
    header: 'First Name',
  },
  {
    accessorKey: 'last_name',
    header: 'Last Name',
  },
  {
    accessorKey: 'email',
    header: 'Email',
  },
  {
    accessorKey: 'role',
    header: 'Role',
  },
  {
    accessorKey: 'status',
    header: 'Status',
  },
];
